var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{attrs:{"fluid":""}},[_c('b-card',[_c('b-card-header',[_c('b-card-title',[_vm._v("Quản lý nguồn đơn hàng")]),_c('b-card-sub-title',[_c('nav-table',{attrs:{"to":{ name: 'orders-source-create'},"name":"Thêm Nguồn đơn mới","exports":_vm.exports_row,"selectedChanged":_vm.selectedProductItems},on:{"confirmDeleteSelected":_vm.confirmDeleteSelected}})],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.orders_columns,"rows":_vm.rows,"search-options":{
          enabled: false,
          externalQuery: _vm.searchTermOrder,
        },"select-options":{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        },"pagination-options":{
          enabled: false,
          perPage:_vm.pageLength
        }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'default')?_c('span',[_c('col-status',{attrs:{"status":props.row.status}})],1):(props.column.field === 'created_at')?_c('span',[_c('div',[_c('FeatherIcon',{attrs:{"icon":"ClockIcon"}}),_vm._v(" "+_vm._s(props.row.created_at))],1),_c('div',[_c('FeatherIcon',{attrs:{"icon":"ClockIcon"}}),_vm._v(" "+_vm._s(props.row.updated_at))],1)]):(props.column.field === 'act')?_c('span',[_c('col-action',{attrs:{"row":props.row.id,"to":{ name: 'orders-source-edit', params: { id: props.row.id}}},on:{"delete":_vm.showMsgBoxConfirmDelete}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }